<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template v-if="currentTabIndex===0">
              <Input v-model="companyName"
                     placeholder="公司名称"
                     class="m-r-10"
                     style="width:160px" />
              <Select v-model="serviceType"
                      placeholder="服务类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in serviceTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===1">
              <Input v-model="serviceName"
                     placeholder="服务商名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="typeName"
                     placeholder="类型名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button type="primary"
                class="m-r-10"
                @click.stop="onClickAddModal">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <template v-if="currentTabIndex===0">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <Dropdown @on-click="onClickBatch">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-xiugai1"
                    size="16"></Icon>
              批量操作
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="urgeDo">批量启用</DropdownItem>
              <DropdownItem name="abandon">批量禁用</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
        <template v-if="currentTabIndex===1">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
        </template>
        <template v-if="currentTabIndex===2">
          <Dropdown @on-click="onClickBatch">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-xiugai1"
                    size="16"></Icon>
              批量操作
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="urgeDo">批量启用</DropdownItem>
              <DropdownItem name="abandon">批量禁用</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <!-- <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop=""
                    type="text">处理</Button>
          </template> -->
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 添加作业企业 -->
    <CompanyModal v-model="addModalVisible"
                  :dataId.sync="companyDataId"></CompanyModal>

    <!-- 添加服务商 -->
    <CompanyServiceModal v-model="companyServiceVisible"
                         :dataId.sync="companyServiceDataId"></CompanyServiceModal>

    <!-- 添加服务类型 -->
    <CompanyServiceTypeModal v-model="companyServiceTypeVisible"
                             :dataId.sync="companyServiceTypeDataId"></CompanyServiceTypeModal>
  </div>
</template>

<script>
import CompanyModal from '@/components/product/admin/modal/archives/CompanyModal.vue'
import CompanyServiceModal from '@/components/product/admin/modal/archives/CompanyServiceModal.vue'
import CompanyServiceTypeModal from '@/components/product/admin/modal/archives/CompanyServiceTypeModal.vue'


export default {
  components: {
    CompanyModal,
    CompanyServiceModal,
    CompanyServiceTypeModal
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [{
        name: '作业公司'
      },
      {
        name: '服务商'
      },
      {
        name: '服务类型'
      },
      ],
      companyName: '',
      serviceType: -1,
      serviceTypeArr: [{
        id: 0,
        name: '全部'
      },
      {
        id: 1,
        name: '车辆维修保养'
      },
      {
        id: 2,
        name: '加水、加油'
      },
      {
        id: 3,
        name: '清运'
      },
      ],
      serviceName: '',
      typeName: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsCompany: [{
        type: 'selection',
        width: 45,
        align: 'center'
      },
      {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '公司名称',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '启用状态',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '服务类型',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '联系人',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '联系人电话',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '公司位置',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '服务项目',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '备注',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        width: 80,
        align: 'center'
      }
      ],
      columnsService: [{
        type: 'selection',
        width: 45,
        align: 'center'
      },
      {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '服务商名称',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '服务商编号',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '启用状态',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '服务类型',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '联系人',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '联系人电话',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '位置',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '备注',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        width: 80,
        align: 'center'
      }
      ],
      columnsServiceType: [{
        type: 'selection',
        width: 45,
        align: 'center'
      },
      {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '服务类型',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '启用状态',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        width: 80,
        align: 'center'
      }
      ],
      data: [],
      selectArr: [],
      addModalVisible: false,
      // 新增 / 修改作业企业
      companyDataId: '',
      companyServiceVisible: false,
      // 新增 / 修改服务商
      companyServiceDataId: '',
      companyServiceTypeVisible: false,
      // 新增 / 修改服务类型
      companyServiceTypeDataId: ''
    };
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsCompany
          break;
        case 1:
          columns = this.columnsService
          break;
        case 2:
          columns = this.columnsServiceType
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.getList()
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    },
    onClickAddModal () {
      if (this.currentTabIndex == 0) {
        this.addModalVisible = true
      } else if (this.currentTabIndex == 1) {
        this.companyServiceVisible = true
      } else if (this.currentTabIndex == 2) {
        this.companyServiceTypeVisible = true
      }
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
