<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>服务商名称：</p>
          <Input v-model="name"
                 placeholder="服务商名称"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>服务商编号：</p>
          <span style="min-width:45px;line-height:32px;">FWS -</span>
          <Input v-model="number"
                 placeholder="服务商编号"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">地址：</p>
          <Input v-model="site"
                 placeholder="地址"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>服务类型：</p>
          <Select v-model="type"
                  placeholder="服务类型">
            <Option :value="item.value"
                    v-for="item in typeArr"
                    :key="item.value">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">联系人：</p>
          <Input v-model="linkMan"
                 placeholder="联系人"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">联系电话：</p>
          <Input v-model="linkPhone"
                 placeholder="联系电话"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'companyServiceModal',
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '服务商'
    },
    dataId: String,
  },
  data () {
    return {
      name: '', // 服务商类型
      number: '', // 服务商编号
      site: '', // 地址
      type: '', // 服务类型
      linkMan: '', // 联系人
      linkPhone: '', // 联系电话
      remark: '', // 备注
      typeArr: [
        {
          name: '车辆维修保养',
          value: 'carMaintain'
        },
        {
          name: '总部',
          value: 'headOffice'
        },
        {
          name: '加水、加油',
          value: 'addWaterOil'
        },
        {
          name: '清运',
          value: 'clear'
        },
        {
          name: '转运',
          value: 'transfer'
        }
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取详情
    getDetail () {

    },
    // 点击确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入服务商名称');
      if (!this.number) return this.$Message.info('请输入服务商编号');
      if (!this.type) return this.$Message.info('请选择服务类型');
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.name = ''
        this.number = ''
        this.site = ''
        this.type = ''
        this.linkman = ''
        this.linkphone = ''
        this.remark = ''
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
