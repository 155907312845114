<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>服务类型：</p>
          <Input v-model="type"
                 placeholder="服务类型"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox" v-model="status"></Checkbox>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '服务类型'
    },
    dataId: String,
  },
  data () {
    return {
      type: '', // 服务类型
      status: true, // 启用状态
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取详情
    getDetail () {

    },
    // 点击确认
    onClickConfirm () {
      if (!this.type) return this.$Message.info('请输入服务类型');
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.type = ''
        this.status = true
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
